<template>
  <div>
    <BlockUI
      v-show="$store.state.showBlock"
      message="Vui lòng chờ trong giây lát"
    >
      <sweetalert-icon
        icon="loading"
        size="12"
      />
    </BlockUI>

    <b-card
      no-body
      m-0
    >
      <b-card-header>
        <b-card-title>Thông tin cấu hình hệ thống</b-card-title>
      </b-card-header>
      <b-card-body>
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col
                cols="12"
                md="4"
              >
                <!-- namThanSo -->
                <validation-provider
                  #default="validationContext"
                  name="Năm thần số"
                >
                  <b-form-group
                    label="Năm thần số"
                    label-for="namThanSo"
                  >
                    <cleave
                      id="namThanSo"
                      v-model="FormCauHinhData.namThanSo"
                      class="form-control"
                      :raw="false"
                      :state="getValidationState(validationContext)"
                      :options="options.number"
                      placeholder="0"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <!-- Form Actions -->
            <div class="d-flex">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
              >
                <feather-icon
                  icon="SaveIcon"
                  class="mr-50"
                />
                Lưu lại
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>

  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCol,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BRow,
  BButton,
} from 'bootstrap-vue'

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { getUserData } from '@/auth/utils'
import cauHinhStoreModule from '@/views/cau-hinh/cauHinhStoreModule'
import Cleave from 'vue-cleave-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    Cleave,
    BFormGroup,
    BCardBody,
    BFormInvalidFeedback,
    BForm,
    BRow,
    BCol,
    BCardTitle,
    BCardHeader,
    ValidationProvider,
    ValidationObserver,
    BCard,
  },
  data() {
    return {
      options: {
        creditCard: {
          creditCard: true,
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's'],
        },
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true,
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true,
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true,
        },
        prefix: {
          prefix: '+63',
          blocks: [3, 3, 3, 4],
          uppercase: true,
        },
      },
    }
  },
  setup() {
    const blankFormCauHinhData = {
      namThanSo: null,
    }

    const FormCauHinhData = ref(JSON.parse(JSON.stringify(blankFormCauHinhData)))

    const USER_APP_STORE_MODULE_NAME = 'app-cau-hinh'
    const toast = useToast()

    const resetuserData = () => {
      FormCauHinhData.value = JSON.parse(JSON.stringify(blankFormCauHinhData))
    }
    const showToast = (variant, titleNotification, iconNotification, contentNotification) => {
      toast({
        component: ToastificationContent,
        props: {
          title: titleNotification,
          icon: iconNotification,
          text: contentNotification,
          variant,
        },
      })
    }

    const currentUser = getUserData()
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, cauHinhStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })
    const onSubmit = () => {
      store.state.showBlock = true

      Object.assign(FormCauHinhData.value, {
        auth: currentUser.auth_key,
        uid: currentUser.id,
      })
      store.dispatch('app-cau-hinh/save', FormCauHinhData.value)
        .then(response => {
          store.state.showBlock = false
          showToast('success', 'Thông báo', 'BellIcon', response.data.content)
        })
        .catch(e => {
          showToast('danger', 'Thông báo', 'BellIcon', e.response.data.message)
          store.state.showBlock = false
        })
    }
    store.state.showBlock = true
    store
      .dispatch('app-cau-hinh/fetchAllData', {
        auth: currentUser.auth_key,
        uid: currentUser.id,
      })
      .then(response => {
        FormCauHinhData.value = response.data
        store.state.showBlock = false
      })
      .catch(() => {
        store.state.showBlock = false
      })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      onSubmit,
      FormCauHinhData,
      resetuserData,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

</style>
